@use 'libs/ui-components/src/lib/scss/shared/_variables.scss';
@use 'libs/ui-components/src/lib/scss/shared/_mixins.scss';

form {
    padding: 0;
    margin: 0;

    p,
    .full-width {
        max-width: variables.$width-max-form;
    }
}

.aix-form__container--center {
    margin: 0 auto;
    max-width: variables.$width-max-form;
}

.aix-form__container {
    position: relative;
    width: 100%;
    max-width: variables.$width-max-form;
    border: 0;
}

/**
 * aix-form__group
 */

.aix-form__group {
    position: relative;
    width: 100%;
    max-width: variables.$width-max-form;
    margin-left: -1px;

    &--textarea {
        textarea {
            border: 1px solid variables.$color-tertiary-black--light;
        }

        &.aix-form__control--invalid.aix-form__control--invalid-required {
            textarea {
                //background-color: $aix-color-secondary--light;
                //transition: background-color $transition-duration, transform $transition-duration;
            }

            label {
                background-color: transparent;
                font-weight: bold;
            }
        }
    }

    &--flex {
        display: flex;
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: variables.$color-quinary-black;
        color: variables.$color-tertiary-black--light;

        @include mixins.hover {
            z-index: mixins.z('default');
            box-shadow: none;

            ~ .aix-label {
                color: variables.$color-tertiary-black--light;
            }
        }
    }
}

.aix-form__group-notification {
    // TODO: Look into what property is indenting these notifications 2px - LMG 2019-07-16
    // This is a hack
    margin-left: -2px;
    width: 100%;
    width: calc(100% + 2px);
}

.aix-form__group-message {
    a {
        text-decoration: none;

        @include mixins.hover {
            text-decoration: underline;
        }
    }
}

/**
 * aix-form__grid
*/

.aix-form__grid {
    min-height: 1px;
}

.aix-form__grid--bordered-right {
    border-right: 1px solid variables.$color-tertiary-black--light;
}

.aix-form__grid--narrow {
    max-width: 610px;
}
