@use 'libs/ui-components/src/lib/scss/shared/_variables';
@use 'libs/ui-components/src/lib/scss/shared/_mixins';

.aix-form__control {
    @include mixins.form-field-style;
    position: relative;
    display: block;
    padding: 24px 16px 8px;
    vertical-align: top;
}

.aix-form__control--action {
    padding-right: 210px;
}

.aix-form__control--xs {
    height: 26px;
    padding: 0;

    @include mixins.hover {
        position: static;
        box-shadow: none;
        transform: none;
    }
}

.aix-form__control--not-hover {
    @include mixins.hover {
        position: static;
        box-shadow: none;
        transform: none;
    }
}

.aix-form__control[required] ~ .aix-label:not(.no-required-star)::after,
.aix-form__control--required ~ .aix-label::after {
    content: ' *';
}

.aix-form__control.aix-disabled,
.aix-form__control[disabled] {
    @include mixins.form-field-disabled-style;
}

.aix-form__control[type='date'] {
    &::-webkit-calendar-picker-indicator {
        font-size: 15px;
        color: variables.$color-tertiary-black;

        &:hover {
            background-color: transparent;
            cursor: pointer;
        }
    }
}

.aix-form__control[type='password'] {
    letter-spacing: 4px;

    &::placeholder {
        letter-spacing: 0;
    }
}

.aix-form__control.ng-dirty.ng-invalid {
    ~ .form-error {
        @include mixins.text-style-label;
        display: block;
        color: variables.$color-red;
        margin-top: -16px;
    }
}

.aix-form__control--textarea {
    height: variables.$height-form-control-textarea;
    padding-top: 0;
    padding-bottom: 16px;
    border: 0;
    resize: none;

    @include mixins.hover {
        box-shadow: none;
        transform: none;
    }
}

.aix-form__control--rich-text {
    height: 120px;
    padding: 24px 0 0;
    margin: 0;
    border: 0;
    resize: none;

    @include mixins.hover {
        box-shadow: none;
        transform: none;
    }
}

.placeholder {
    color: variables.$color-tertiary-black--light;
}

.aix-form__big-selector {
    @include mixins.fz(2.9);
    border: 0;
    color: variables.$aix-color-primary;
}

// This is the label that appears inside the form field itself
select + .aix-form__label,
input + .aix-form__label,
textarea + .aix-form__label,
ngx-editor + .aix-form__label,
aix-dropdown + .aix-form__label {
    position: absolute;
    z-index: mixins.z('layer-3');

    left: 16px;
    top: 8px;

    font-weight: 400;
    color: variables.$color-tertiary-black;
}

textarea + .aix-form__label {
    left: 1px;
    top: 1px;
    background-color: variables.$color-white;
    width: calc(100% - 2px);
    padding-left: 15px;
    padding-top: 7px;
}

ngx-editor + .aix-form__label {
    left: 1px;
    top: 36px;
    background-color: variables.$color-white;
    width: calc(100% - 2px);
    padding-left: 15px;
    padding-top: 7px;
}

.aix-form__action-link {
    position: absolute;
    z-index: mixins.z('layer-3');

    right: 22px;
    top: 18px;

    a {
        display: inline-block;
        @include mixins.fz(1);
        color: variables.$color-primary-black;
    }

    .icon {
        color: variables.$aix-color-primary;
        @include mixins.fz(3);
        vertical-align: -6px;
    }
}

.aix-form__magnifier {
    position: absolute;
    z-index: mixins.z('layer-3');

    right: 20px;
    top: 14px;

    .icon {
        color: variables.$color-tertiary-black--light;
        @include mixins.fz(2);
    }
}

.aix-form__add-link {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &-title {
        color: variables.$color-tertiary-black;
        @include mixins.fz(1.4);
        @include mixins.lh(1.7);
    }

    &-icon {
        margin-right: 5px;
        @include mixins.fz(3);
        @include mixins.lh(3);
        color: variables.$aix-color-primary;
    }
}

.aix-slide-toggle-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
}

.aix-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-right: 8px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.aix-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
        position: absolute;
        content: '';
        height: 17px;
        width: 17px;
        left: 3px;
        bottom: 3px;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-width: 1px;
        border-style: solid;
    }

    &.aix-disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    &.round {
        border-radius: 34px;
        border-width: 1px;
        border-style: solid;

        &:before {
            border-radius: 50%;
        }
    }
}

input:checked + .aix-slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

input[type='text'].aix-form__control,
input[type='password'].aix-form__control,
input[type='email'].aix-form__control,
input[type='url'].aix-form__control,
input[type='tel'].aix-form__control,
textarea.aix-form__control {
    appearance: none;
    border-radius: 0;
}

input[type='text'].aix-form__control.aix-form__control__currency:placeholder-shown {
    text-indent: 10px;
}

@-moz-document url-prefix() {
    input[type='text'].aix-form__control.aix-form__control__currency:placeholder-shown {
        padding-left: 10px;
    }
}

input[type='text'].aix-form__control.aix-form__control__currency:placeholder-shown::placeholder {
    text-indent: -10px;
    padding-left: 10px;
}

// /*
// Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
// since IE8 won't execute CSS that contains a CSS3 selector.
// */
input.aix-form__control:not([type]) {
    display: inline-block;
    padding: 0.5em 0.6em;
    border-radius: 4px;
}

// /* Chrome (as of v.32/34 on OS X) needs additional room for color to display. */
// /* May be able to remove this tweak as color inputs become more standardized across browsers. */
input.aix-form__control[type='color'] {
    padding: 0.2em 0.5em;
}

.aix-form__control:focus {
    outline: 0;
    z-index: mixins.z('layer-0');
}

// /*
// Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
// since IE8 won't execute CSS that contains a CSS3 selector.
// */
input.aix-form__control:not([type]):focus {
    outline: 0;
    border-color: variables.$color-tertiary-black;
}

input.aix-form__control[type='file']:focus,
input.aix-form__control[type='radio']:focus,
input.aix-form__control[type='checkbox']:focus {
    outline: thin solid variables.$color-tertiary-black;
    outline: 1px auto variables.$color-tertiary-black;
}

// /*
// Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
// since IE8 won't execute CSS that contains a CSS3 selector.
// */
input.aix-form__control:not([type])[disabled] {
    cursor: not-allowed;
    background-color: variables.$color-quinary-black;
    border-color: variables.$color-quaternary-black;
    color: variables.$color-tertiary-black--light;
}

input.aix-form__control[readonly],
select.aix-form__control[readonly],
textarea.aix-form__control[readonly] {
    background-color: variables.$color-quinary-black; // /* menu hover bg color */
    color: variables.$color-tertiary-black; // /* menu text color */
    border-color: variables.$color-quaternary-black;
}

select.aix-form__control[multiple] {
    height: auto;
}

select.aix-form__control {
    // /* Normalizes the height; padding is not sufficient. */
    height: variables.$height-form-control;
    cursor: pointer;

    @supports ((-webkit-appearance: none) or (-moz-appearance: none) or (appearance: none)) or
        (-webkit-appearance: none) {
        appearance: none;
        padding-right: 30px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE2IDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgxNkw4IDgiIGZpbGw9IiM3MjdDODQiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
        background-position: right 15px top 20px;
        background-repeat: no-repeat;
        border-radius: 0;
        color: variables.$color-black;
    }
}

.aix-form__hint {
    margin: -8px 0 16px 0;
}

.aix-form__group--textarea {
    .aix-form__hint {
        margin: 8px 0 16px 0;
        padding-bottom: 8px;
    }
}
